import React, { Component } from "react";
// import qrcode from "./qrcode.jpg";
// import { Document, Page } from "react-pdf";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas } from "@fortawesome/free-solid-svg-icons";
import Radium, { StyleRoot } from "radium";
import { slideInDown, fadeInRight, zoomIn } from "react-animations";
// import { useWebPSupportCheck } from "react-use-webp-support-check";
import { checkWebPSupport } from "supports-webp-sync";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import "./App.css";
// library.add(fas);

const year = new Date().getFullYear();
class App extends Component {
  state = {
    isIndex: true,
    numPages: null,
    pageNumber: 1,
    pdffile: null,
    loadProgress: 0,
    navTagIndex: 0,
    preloadImgs: [
      "/images/2021-00.jpg",
      "/images/2021-2.jpg",
      "/images/2021-3.jpg",
      "/images/2021-4.jpg",
      "/images/2021-5.jpg",
      "/images/2021-6.jpg",
      "/images/2021-7.jpg",
      "/images/2021-8.jpg",
      "/images/2021-9.jpg",
      "/images/2021-10.jpg",
      "/images/2021-11.jpg",
      "/images/2021-12.jpg",
      "/images/2021-13.jpg",
      "/images/2021-14.jpg",
      "/images/2021-15.jpg",
      "/images/2021-16.jpg",
      "/images/2021-17.jpg",
      "/images/2021-18.jpg",
      "/images/2021-19.jpg",
      "/images/2021-20.jpg",
      "/images/2021-21.jpg",
      "/images/2021-22.jpg",
      "/images/2021-23.jpg",
      "/images/2021-24.jpg",
      "/images/2021-25.jpg",
      "/images/2021-26.jpg",
      "/images/2021-27.jpg",
      "/images/2021-28.jpg",
      "/images/2021-29.jpg",
      "/images/2021-30.jpg",
      "/images/2021-31.jpg",
      "/images/2021-32.jpg",
      "/images/2021-33.jpg",
      "/images/2021-34.jpg",
      "/images/2021-35.jpg",
      "/images/2021-36.jpg",
      "/images/2021-37.jpg",
      "/images/2021-38.jpg",
      "/images/2021-39.jpg",
      "/images/2021-40.jpg",
      "/images/2021-41.jpg",
      "/images/2021-42.jpg",
      "/images/2021-43.jpg",
      "/images/2021-44.jpg",
      "/images/2021-45.jpg",
      "/images/2021-46.jpg",
      "/images/2021-47.jpg",
      "/images/2021-48.jpg",
      "/images/2021-49.jpg",
      "/images/2021-50.jpg",
      "/images/2021-51.jpg",
      "/images/2021-52.jpg",
      "/images/2021-53.jpg",
      "/images/2021-54.jpg",
      "/images/2021-55.jpg",
      "/images/2021-56.jpg",
      "/images/2021-57.jpg",
      "/images/2021-58.jpg",
      "/images/2021-59.jpg",
      "/images/2021-60.jpg",
      "/images/2021-61.jpg",
      "/images/2021-62.jpg",
      "/images/2021-63.jpg",
      "/images/2021-64.jpg",
      "/images/2021-65.jpg",
      "/images/2021-66.jpg",
      "/images/2021-67.jpg",
      "/images/2021-68.jpg",
      "/images/2021-69.jpg",
      "/images/2021-70.jpg",
    ],
    miniViewList: [],
    miniViewNavTags: [],
    miniViewNavSubs: [],
    miniViewNavSubsObject: {
      13: ["15", "16", "17", "18", "19"],
      20: ["21", "22", "23"],
      52: ["53"],
      42: ["43", "44", "45", "46", "47", "48", "49", "50", "51"],
      37: ["41"],
      // 25: ["26", "27", "28", "29", "30", "31", "32"],
      24: ["30", "38", "25", "26"],
      32: ["33", "34"],
      35: ["39", "36"],
      // 36: ["37"],
      58: ["58", "59", "60"],
    },
    SwiperConfig: {
      effect: "coverflow",
      grabCursor: true,
      lazy: true,
      loop: true,
      // activeSlideKey: 1,
      rebuildOnUpdate: true,
      shouldSwiperUpdate: true,
      centeredSlides: true,
      slidesPerView: "auto",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // coverflowEffect: {
      //   rotate: 50,
      //   stretch: 0,
      //   depth: 100,
      //   modifier: 1,
      //   slideShadows: false
      // },

      // pagination: {
      //   el: ".swiper-pagination"
      // }
    },
  };
  componentWillMount() {
    setTimeout(() => {
      this.onPreloadImgs(this.state.preloadImgs);
    }, 1000);
  }
  onPreloadImgs(imgs) {
    const unitValue = 100 / imgs.length;
    let loadProgress = this.state.loadProgress;
    for (let img of imgs) {
      console.log(img);
      let image = new Image();
      image.src = img;
      image.onload = () => {
        loadProgress += unitValue;
        console.log(loadProgress + "%");
        this.setState({
          loadProgress,
        });
      };
    }
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  onViewPdf = (url) => {
    this.setState({ pdffile: url || null, pageNumber: 1 });
  };
  onGoPrevPage = () => {
    let pageNumber = (this.state.pageNumber || 0) - 1;
    if (this.state.numPages) {
      this.setState({
        pageNumber,
      });
    }
  };
  onGoNextPage = () => {
    let pageNumber = (this.state.pageNumber || 0) + 1;
    if (this.state.numPages && this.state.pageNumber < this.state.numPages) {
      this.setState({
        pageNumber,
      });
    }
  };
  onChangeMiniViewList(val, type) {
    const isIndex = !val.length > 0;
    if (type === "miniViewList") {
      this.setState({
        isIndex,
        miniViewNavTags: [],
        miniViewNavSubs: [],
        [type]: val,
      });
    }

    if (type === "miniViewNavTags") {
      this.setState({
        isIndex,
        miniViewList: [],
        [type]: val,
      });
    }
    if (type === "miniViewNavSubs") {
      this.setState({
        isIndex,

        miniViewList: [],
        [type]: val,
      });
    }
  }
  render() {
    const {
      // pageNumber,
      // numPages,
      // pdffile,
      // supportsWebP,
      navTagIndex,
      miniViewList,
      miniViewNavTags,
      miniViewNavSubs,
      miniViewNavSubsObject,
      isIndex,
      SwiperConfig,
      loadProgress,
    } = this.state;
    const supportsWebP = false;
    const miniViewBoxElement = miniViewList.map((item, index) => {
      const styles = {
        slideInDown: {
          animation: `x ${100 * (2 + index / 10)}ms`,
          animationName: Radium.keyframes(slideInDown, "slideInDown"),
        },
        fadeInRight: {
          animation: `x ${100 * (2 + index / 2)}ms`,
          animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
        },
        zoomIn: {
          animation: `x ${200 * (2 + index / 2)}ms`,
          animationName: Radium.keyframes(zoomIn, "zoomIn"),
        },
      };
      return (
        <StyleRoot>
          <div className="mini-view-item" key={item} style={styles.zoomIn}>
            <img
              src={`/images/2021-${item}.${supportsWebP ? "webp" : "jpg"}`}
              alt={item}
            />
          </div>
        </StyleRoot>
      );
    });
    const miniViewNavTagElement = miniViewNavTags.map((item, index) => {
      const styles = {
        slideInDown: {
          animation: `x ${100 * (2 + index / 10)}ms`,
          animationName: Radium.keyframes(slideInDown, "slideInDown"),
        },
        fadeInRight: {
          animation: `x ${200 * (2 + index / 2)}ms`,
          animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
        },
      };
      return (
        <StyleRoot>
          <div
            className="mini-view-item mini-view-nav-tag"
            style={styles.fadeInRight}
            onClick={() => {
              this.setState({
                navTagIndex: item,
              });
              this.onChangeMiniViewList(
                miniViewNavSubsObject[item]
                  ? miniViewNavSubsObject[item]
                  : [item],
                "miniViewNavSubs"
              );
            }}
            key={item}
          >
            <img
              className={navTagIndex === item ? "active" : ""}
              src={`/images/2021-${item}.${supportsWebP ? "webp" : "jpg"}`}
              alt={item}
            />
          </div>
        </StyleRoot>
      );
    });
    const miniViewNavSubElement = miniViewNavSubs.map((item) => {
      return (
        <span key={item + new Date().getTime()}>
          <div
            className="swiper-slide mini-view-nav-sub-item"
            style={{
              color: "#dedede",
              backgroundImage: `url(/images/2021-${item}.${supportsWebP ? "webp" : "jpg"
                })`,
            }}
            key={item}
          ></div>
        </span>
      );
    });
    return (
      <div className="main">
        <div className="navbar">
          <div className="menu">
            <div
              className="menu-item"
              onClick={(e) => {
                this.onChangeMiniViewList([], "miniViewList");
              }}
            >
              首页
              <br />
              <small> Home </small>
              <div
                className="sub-nav-menu-list"
                style={{
                  width: "140px",
                  left: "-80px",
                }}
              >
                <div
                  className="nav-tags-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onChangeMiniViewList(
                      ["2", "3", "68"],
                      "miniViewNavTags"
                    );

                    this.onChangeMiniViewList(
                      ["2", "3", "68"],
                      "miniViewNavSubs"
                    );
                    // this.onChangeMiniViewList(
                    //   ["56", "57", "58", "59", "60", "61", "62"],
                    //   "miniViewNavSubs"
                    // );
                  }}
                >
                  公司介绍
                  <br />
                  <small>Company Introdution</small>
                </div>
              </div>
            </div>
            <div className="menu-item">
              业务概览
              <br />
              <small> Business Overview </small>
              <div
                className="sub-nav-menu-list"
                style={{
                  left: "-50px",
                }}
              >
                <div
                  className="nav-tags-item"
                  onClick={() => {
                    this.onChangeMiniViewList(
                      ["24", "32", "35"],
                      "miniViewNavTags"
                    );
                    this.onChangeMiniViewList(["63"], "miniViewNavSubs");
                  }}
                >
                  明星合作
                  <br />
                  <small>Pop Star Cooperation</small>
                </div>
                <div
                  className="nav-tags-item"
                  onClick={() => {
                    this.onChangeMiniViewList(
                      ["31", "29", "27", "28"],
                      "miniViewNavTags"
                    );
                    this.onChangeMiniViewList(["64"], "miniViewNavSubs");
                  }}
                >
                  大V合作
                  <br />
                  <small>KOL Cooperation</small>
                </div>
                <div
                  className="nav-tags-item"
                  onClick={() => {
                    this.onChangeMiniViewList(
                      ["13", "20", "52", "42", "37"],
                      "miniViewNavTags"
                    );
                    this.onChangeMiniViewList(["14"], "miniViewNavSubs");
                  }}
                >
                  品牌活动
                  <br />
                  <small>Branding Campaign</small>
                </div>
                <div
                  className="nav-tags-item"
                  onClick={() => {
                    // this.onChangeMiniViewList([], "miniViewNavTags");
                    this.onChangeMiniViewList([], "miniViewNavTags");
                    this.onChangeMiniViewList(
                      [
                        "54",
                        "55",
                        "56",
                        "57",
                        "58",
                        "59",
                        "60",
                        "61",
                        "11",
                        "12",
                        "9",
                        "10",
                      ],
                      "miniViewNavSubs"
                    );
                  }}
                >
                  历史概览
                  <br />
                  <small> History Overview</small>
                </div>
              </div>
            </div>
            <div
              className="menu-item"
            // onClick={() => {
            //   this.onChangeMiniViewList([], "miniViewNavTags");
            //   this.onChangeMiniViewList(["2", "68", "71"], "miniViewNavSubs");
            // }}
            >
              在线服务
              <br />
              <small>Online Service </small>
              <div
                className="sub-nav-menu-list"
                style={{
                  maxWidth: "100px",
                  left: "-50px",
                }}
              >
                <div
                  className="nav-tags-item"
                  onClick={() => {
                    window.open("https://jinshuju.net/f/T1hKFn", "_blank");
                  }}
                >
                  客户中心
                  <br />
                  <small> Customer Center </small>
                </div>
                <div
                  className="nav-tags-item"
                  onClick={() => {
                    this.onChangeMiniViewList([], "miniViewNavTags");
                    this.onChangeMiniViewList(["69"], "miniViewNavSubs");
                    // this.onChangeMiniViewList(
                    //   ["2", "68", "71"],
                    //   "miniViewNavSubs"
                    // );
                  }}
                >
                  联系我们
                  <br />
                  <small> Contact Us </small>
                </div>

                <div
                  className="nav-tags-item"
                  onClick={() => {
                    window.open("https://jinshuju.net/f/HMi4ZW", "_blank");
                  }}
                >
                  加入我们
                  <br />
                  <small> Join Us </small>
                </div>
              </div>
            </div>
            {/* <div
              className="menu-item"
              onClick={() => {
                window.open("https://jinshuju.net/f/HMi4ZW", "_blank");
              }}
            >
              加入我们
              <br />
              <small> Join Us </small>
            </div> */}
          </div>
        </div>
        <div
          className={`container-view-wrapper ${isIndex ? "home-index" : ""}`}
        >
          <div className="container-view">
            {miniViewList && miniViewList.length > 0 ? (
              <div className="mini-view-box" key="1">
                {miniViewBoxElement}
              </div>
            ) : (
              ""
            )}
            <div
              key="2"
              className={`mini-nav-view-box-wrapper ${isIndex ? "home-index" : ""
                }`}
            >
              {miniViewNavTags && miniViewNavTags.length > 0 ? (
                <div className="mini-nav-view-box">{miniViewNavTagElement}</div>
              ) : (
                ""
              )}
              {miniViewNavSubs && miniViewNavSubs.length > 0 ? (
                <div className="mini-sub-view-box">
                  <Swiper
                    {...SwiperConfig}
                    slidesPerView={miniViewNavSubs.length > 2 ? 1 : "auto"}
                    // allowSlideNext={miniViewNavSubs.length > 1}
                    shouldSwiperUpdate={miniViewNavSubs.length > 1}
                    key={navTagIndex}
                    navigation={{
                      nextEl:
                        miniViewNavSubs.length > 1 ? ".swiper-button-next" : "",
                      prevEl:
                        miniViewNavSubs.length > 1 ? ".swiper-button-prev" : "",
                    }}
                    loop={miniViewNavSubs.length > 1}
                  >
                    {miniViewNavSubElement}
                  </Swiper>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="nav-tags">
            {/* <div
              className="nav-tags-item"
              onClick={() => {
                this.onChangeMiniViewList(["6", "7", "8"], "miniViewList");
              }}
            >
              VI
            </div>
            <div
              className="nav-tags-item"
              onClick={() => {
                this.onChangeMiniViewList(
                  ["10", "11", "12", "13"],
                  "miniViewList"
                );
              }}
            >
              Strategy
            </div>
            <div
              className="nav-tags-item"
              onClick={() => {
                this.onChangeMiniViewList(
                  ["14", "21", "25", "36", "33"],
                  "miniViewNavTags"
                );
                this.onChangeMiniViewList(
                  ["15", "16", "17", "18", "19", "20"],
                  "miniViewNavSubs"
                );
              }}
            >
              Campaign
            </div>
            <div
              className="nav-tags-item"
              onClick={() => {
                this.onChangeMiniViewList([], "miniViewNavTags");
                this.onChangeMiniViewList(
                  ["43", "44", "45", "46", "47", "48", "49", "50", "51", "52"],
                  "miniViewNavSubs"
                );
              }}
            >
              Marketing
            </div>
            <div
              className="nav-tags-item"
              onClick={() => {
                this.onChangeMiniViewList(["41", "42"], "miniViewList");
              }}
            >
              Social Media Content Service
            </div>
            <div
              className="nav-tags-item"
              onClick={() => {
                this.onChangeMiniViewList([], "miniViewNavTags");
                this.onChangeMiniViewList(
                  ["56", "57", "58", "59", "60", "61", "62"],
                  "miniViewNavSubs"
                );
              }}
            >
              History Overview
            </div> */}
          </div>
        </div>
        {/* <div className="container">
          <div className="title">
            扫码关注
            <br />
            <b>「韵知文化」</b>
            <br />
          </div>
          <div className="qrcode">
            <img src={qrcode} alt="韵知文化公众号二维码" />
          </div>
        </div> */}

        {/* {pdffile ? (
          <div className="pdf-viewer">
            <Document
              file={pdffile}
              loading="loading..."
              renderMode="svg"
              onLoadSuccess={this.onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>
            {pageNumber > 1 ? (
              <div className="page-prev" onClick={this.onGoPrevPage}>
                <FontAwesomeIcon icon={"arrow-left"} />
              </div>
            ) : (
              ""
            )}
            {pageNumber < numPages ? (
              <div className="page-next" onClick={this.onGoNextPage}>
                <FontAwesomeIcon icon="arrow-right" />
              </div>
            ) : (
              ""
            )}

            <p className="page-number">
              {pageNumber}/{numPages}
            </p>
            <p className="file-download">
              <a
                href="./上海韵知文化传播有限公司（2020）.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon="file-download" />
              </a>
            </p>
          </div>
        ) : (
          ""
        )} */}
        <footer className="footer">
          <span>
            © {year} 上海韵知文化传播有限公司
            <br />
            <a
              href="http://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              沪ICP备17056224号-1
            </a>
          </span>
        </footer>
        {loadProgress <= 98 ? (
          <div
            className="loading-box"
            style={{
              backgroundColor: `rgba(170, 170, 170, ${1 - loadProgress / 100})`,
            }}
          >
            <div
              className="loader qrcode-loader"
              style={{
                backgroundColor: `rgba(0, 0, 0, ${1 - (loadProgress / 100) * 1.3
                  })`,
              }}
            >
              <div className="loader-inner ball-clip-rotate-multiple">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default App;
